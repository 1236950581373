<template>
  <div class="home">
    <div class="top">
      <div class="back" @click="onClickLeft('/')">
        <img src="../assets/img/back.png" alt=""  />
      </div>
      <div class="title">
        {{$t('m.yxxq')}}
      </div>
    </div>  

 <div class="content">  
     <div class="actives fadeInDow">  
        <van-swipe
        class="my-swipe1"
        :autoplay="3000"
        :show-indicators="false"      >
        <van-swipe-item v-for="(item, index) in banner" :key="index">
          <img :src="dqyy =='zh-CN'? item.thumb:item.thumb_en" alt="" class="imgss" />
        </van-swipe-item>
      </van-swipe>  
      <!-- <div class="notific">  
        <div class="right">
        <van-notice-bar
  background="#2a2a2a"
  color="#ffffff"
  :text="$t('m.notice')"
/>   </div>
      </div> -->
      
      </div>

 
     <div class="mtop">
      <div class="title">{{dqyy=='zh-CN'?keyInfo.name:keyInfo.nameEn}}</div>
     </div>
    
<!-- 添加流动性 -->
<div class="card">
      <div class="titles" @click="getList">{{$t('m.jryx')}}</div>
      <div class="buttom">
        <van-button
          type="primary"
          class="btn act"
          @click="toJoin()"
        >
        {{$t('m.ljcy')}} </van-button
        >
      </div>
      <div class="item" v-for="(item, i) in list" :key="i">
      <div class="box"   v-if="i<isMore">     
          <div class="cont " >         
         <div class="ibox">
          <div class="txts" v-if="item.status==1"  @click="toshow(i,item.show)">{{$t('m.dqrs')}}{{item.num}} / {{keyInfo.num}} </div>
          <div class="rbox" @click="toPage(item)">
            <div class="left">
            <div class="num">N0.{{item.no}}</div>
            <div class="txt">{{$t('m.lun')}}</div>  
          </div>         
          <div class="txt">{{item.status==1?$t('m.jxz'):$t('m.yjs')}} </div>
          </div>
          <div class="list" v-if="item.show">
          <div class="item fadeIn" v-for="(sitem,is) in item.list" :key="is">
            <div class="xh">{{is+1}}</div>
            <div class="">{{sitem.trxAddress|yingcang}}</div>
          </div>
        </div>
         </div>
        </div>
      </div>
      
      </div>
      <div class="rmore" @click="toMore">加载更多</div>
     
     </div>  
    

      
    <div class="buttoms">Copyright © 2024 DA. All rights reserved.</div>

      <!-- <div class="flexbottom fadeInUp" @click="handleFiexdToolItem('index')">
       <div class="txt"> DA</div>
       <div class="txt">DA MEET YOU</div>
      </div> -->
    </div>


   

<!-- 授权 -->
 <van-popup v-model="impower" round :close-on-click-overlay="false">
      <div class="popup">       
        <div class="sqtxt">{{$t('m.sqinfo')}}</div>
        <div class="flsz">       
         <div class="btn" @click="approve">
          {{ sqloading ? $t("m.sqload") : $t("m.sqbtn") }}
        </div>
        </div>
      </div>
    </van-popup>
    
<!-- 领取 -->
 <van-popup v-model="lqshow" round :close-on-click-overlay="false">
      <div class="popup">
           <div class="top"> <div class="title">{{$t('m.qrlq')}}</div>
        <img src="../assets/img/gbs.png" alt="" class="close" v-if="!lqloading" @click="lqshow=false"/>
    </div>
        <div class="sqtxt">{{$t('m.ny')}}</div>
          <div class="rbox">
            <div class="num">{{lqNum||0}}</div>
            <div class="txt">DA</div>
            <img src="../assets/img/logo.png" alt="" srcset="" class="ico">
          </div> 
        <div class="flsz"> 
         <van-button
          :loading="lqloading"
          type="primary"
          @click="scSelect"
          class="btn"
        >
         {{$t('m.lqlj')}}</van-button
        >
        </div>
      </div>
    </van-popup>

    <!-- 领取余额 -->
 <van-popup v-model="lqshows" round  :close-on-click-overlay="false">
      <div class="popup">   
        <div class="ttop">
          <div class="title">{{$t('m.cyyx')}}</div>
            <img src="../assets/img/gbs.png" alt="" class="close" v-if="!lqloading" @click="lqshows=false;loading=false"/>
          </div>    
        <!-- <div class="sqtxts">{{$t('m.zyyyyd')}}</div> -->
        
         <div class="rbox">
            <div class="num">{{keyInfo.joinAmount||0}}</div>
            <div class="txt">DA</div>
            <img src="../assets/img/logo.png" alt="" srcset="" class="ico">
          </div>       
          <div class="xbox" >
          <div class="txt">{{$t('m.wdye')}} </div>
          <div class="num">{{userInfo.daAmount}} DA</div>
        </div>
        <div class="flsz">       
         <van-button
          :loading="lqloading"
          type="primary"
          @click="joinRound"
          class="btn"
        >
        {{$t('m.qr')}}</van-button
        >
        </div>
      </div>
    </van-popup>

    <van-popup v-model="shows" round :close-on-click-overlay="false">
      <div class="popup">
        <div class="top"> 
        <div class="title">{{ $t("m.dltt") }}</div></div>
        <div class="txt">{{ $t("m.dlinfo") }}</div>
        <div class="btns" @click="ethConnect">{{ $t("m.dlbtn") }}</div>
        <div class="lang"  @click="changer(dqyy)">
          <img src="../assets/img/cny.png" alt="" />
          <span>{{ $t("m.xzlang") }}</span>
        </div>
      </div>
    </van-popup>



   
  </div>
</template>

<script>
import { connectTron ,getCount,getRoundNows,getProved,getProveds,getDA,toApproves,toJoinRound,getUser} from "@/api/Public";
import Header from '../components/header.vue';
export default {
  name: "Home",
  components: {Header},
  data() {
    return {
       sxshow:false,
      xzNum:'',
      bdshows: false,
      shows: false,
      impower: false,
      userWallet: "",
      yqmcode: "",
      Time: "",
      sqloading: false,
      bdloading: false,
      userInfo:"",
      endTime:0,
      lqshow:false,
      lqshows:false,
      lqloading:false,
      isMore:10,
      myDa:0,
        banner: [ 
        {  "thumb": require('../assets/img/banner.jpg'),"thumb_en": require('../assets/img/banners.jpg')},
        {  "thumb": require('../assets/img/banner1.jpg'),"thumb_en": require('../assets/img/banner1.jpg')},
        {  "thumb": require('../assets/img/banner2.jpg'),"thumb_en": require('../assets/img/banner2s.jpg')},

      ],   
        list:[],
        time:'',
        exshow:false,
        exshows:false,
        exloading:false,
        exmcode:'',
        values:0,
        exnum:'',
        twAmount:'',        
        sqNum:0,
        sqNums:0,
        sqUsdtNum:0,
        sqArcNum:0,
        lqNum:0,
        lqType:0,
        pool:0,
        keyInfo:""

    };
  },
  filters: {
      Endtime(e){
    var    end_str = (e).replace(/-/g,"/");//一般得到的时间的格式都是：yyyy-MM-dd hh24:mi:ss，所以我就用了这个做例子，是/的格式，就不用replace了。  
    var end_date =new Date(end_str)-new Date;//将字符串转化为时间  
    return end_date
    },
   
    formatDate(e) {
      if (e == 0) {
        return "";
      } else {
        let date = new Date(e * 1000);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();
        if (M < 10) {
          M = "0" + M;
        }
        if (D < 10) {
          D = "0" + D;
        }
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        return `${Y}-${M}-${D} ${h}:${m}:${s}`;
      }
    },
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{10})\w+(\w{10})/, "$1*****$2");       
        return a;
      } else {
        return "...";
      }
    },
      time(time){
      if(time){
        var reg = /^(\d{4})-(\d{1,2})-(\d{1,2})\s(\d{1,2})(:(\d{1,2})){2}$/;
        var end = time.match(reg).slice(1,4).join('-');
        return end
      }
    }
  },
   watch: {
    "$store.getters.userWallet": function (val) {
       this.pustadd(val)
    },
     "$store.getters.nlist": function (val) {
      //  console.log(val.length);
       this.mapdata()
    },
    deep: true,
    immediate: true,
  },
  // beforeCreate(){
  //   this.ethConnect()
  // },
  beforeCreate() {
    var i = 0;
    var a = setInterval(() => {
      if (typeof window.ethereum !== "undefined") {
        connectTron().then(
          (info) => {
            clearInterval(a);
            if (info[0].indexOf("0x") == 0) {
              if(this.$store.getters.myFil!=info[0]){  
                this.userWallet =  info[0]     
                // this.impower=true
                this.$store.commit("userWallet", info[0]); 
                // this.yanzheng(info[0])
              }else{
                this.$store.commit("userWallet", info[0]); 
                this.pustadd(info[0])                  
              }
              //    
              //      
              
            } else {
              this.$notify("请使用HECO地址登录游戏");
            }
          }
        );
      }
      if (i > 5) {
        this.$notify("钱包失联了！！");
        clearInterval(a);
      }
      console.log(i);
      i++;
    }, 1000);
  },
  
  created() { 
     this.keyInfo=JSON.parse(sessionStorage.getItem('key'))
     this.getInfo()
    this.userWallet = this.$store.getters.userWallet; 
     
  },

  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },  
  },
  methods:{
   
    onClickLeft(val) {
       this.$router.replace({ path:val})
    },
    async getInfo(){
      let {data: { code, data, message },} = await this.$http.get(this.$api.getRegionDetail,{id:this.keyInfo.id});
      if(code==200){
          this.list=[]
          this.keyInfo=data
          this.getList()
      }
    },
    toMore(){
      this.isMore= this.isMore+20
    },
    async getList(){
    
      if(this.keyInfo.currentRound==1){
        let data={
                no:1,
                num:0,
                status:1,
                show:false,
                list:[]
          }
          this.list.unshift(data)
          this.getUlsit(0)
      }else{
       
        let crun=this.keyInfo.currentRound
        for (let i = 0; i < Number(crun); i++) {   
            if(i==crun-1){
                  let data={
                        no:i+1,
                        num:0,
                        status:1,
                        show:false,
                        list:[]
                  }
                  this.list.unshift(data)
              this.getUlsit(0)
            }else{
              let obj={
                  no:i+1,
                  num:12,
                  status:2,
                  show:false,
                  list:[]
                }
              this.list.unshift(obj)
            }
          
              } 
      }
    },

    async getUlsit(num){
      let {data: { code, data, message },} = await this.$http.get(this.$api.getRegionHistoryList,{
        pageNum:1,
        pageSize:500,
        round:this.keyInfo.currentRound,
        rid:this.keyInfo.id});
      if(code==200){
          this.$set(this.list[num],'list',data.reverse())
          this.$set(this.list[num],'num',data.length)
          if(data.length==12){
            this.getInfo()
          }
      }      
    },
   

    
   async toJoin(){     
      let {data: { code, data, message },} = await this.$http.get(this.$api.getWallet, {
       trxAddress: this.$store.getters.userWallet,        
      });
      if(code==200){
        this.userInfo=data
        this.lqshows=true
      }
    },

    async joinRound(){
      if(this.keyInfo.joinAmount-this.userInfo.daAmount>0){
        return}
      this.lqloading=true
      let {data: { code, data, message },} = await this.$http.post(this.$api.joinRound, {
       trxAddress: this.$store.getters.userWallet,       
       gameAmount:this.keyInfo.joinAmount,
       regionRound:this.keyInfo.currentRound,
       rid:this.keyInfo.id,
       name:this.keyInfo.name,
       nameEn:this.keyInfo.nameEn,
       uid:this.$store.getters.myID,
      });
      this.lqloading=false
      if(code==200){
        this.lqshows=false
        this.upData()
        this.$notify({message:this.$i18n.t("m.cycg"),type: "success",});
      }else{
        this.$notify({message: message,type: "danger",});
      }
    },

 async upData(){
    this.getUlsit(0)
 },








 
      getLists(){
      getCount(this.keyInfo.id).then(res=>{
        console.log(res,'getCount');
        if(res!=""){
            this.list=[]
            if(res==0){
              let data={
                no:0,
                num:0,
                status:1,
                show:false,
                list:[]
              }
              getRoundNows(this.keyInfo.id).then(num=>{
                data.num=num.length
                data.list=num
                this.list.unshift(data)
              })
              
            }else if(res>0){
              for (let i = 0; i < Number(res)+1; i++) {    
                console.log('i',i);            
                let obj={
                  no:i,
                  num:12,
                  status:2,
                  show:false,
                  list:[]
                }
                if(i==res){
                getRoundNows(this.keyInfo.id).then(num=>{
                  if(num){
                obj.num=num.length
                obj.list=num
                obj.status=num.length<12?1:2,
                obj.show=num.length<12?true:false
                  }
                this.list.unshift(obj)
              })
            }else{
              this.list.unshift(obj)
            }
              }         
            }
        }
      })
    },
    toshow(e,j){
      this.$set(this.list[e],'show',!j)
    },
    toRoundInfo(i){
      getRoundHistroys(this.keyInfo.id,i).then(res=>{

      })
    },
    togetMyDeedPool(){
      getMyDeedPool().then(res=>{
        this.pool=(res*1).toFixed(4)      })
    },
    toLqShow(num,t){
      this.lqNum=num
      this.lqType=t
      this.lqshow=true
    },
      
    
    onSelect(e){
      this.xzNum=e.text
      this.sxshow=false
    },
    async toJoins(){
      if(this.keyInfo){ 
        this.loading=true
        this.sqArcNum=this.keyInfo.stakeAmount
        getDA(this.$store.getters.userWallet).then(res=>{this.myDa=(res*1).toFixed(4)})
        getProved(this.userWallet).then(res=>{
          console.log(res);
          this.sqNum =res
          this.lqshows=true         
        })
      }
    },
    async toGetsq(){
      this.sqNum=await getProved(this.userWallet).then(res=>{ return res})
      this.sqNums=await getProveds(this.userWallet).then(res=>{ return res})
      console.log(this.sqNum,this.sqNums);
    },
   async scSelects(){
       await this.toGetsq()
       if(this.sqArcNum<=0){return}
       if(!this.userWallet){return}
      this.lqloading=true
      console.log(this.sqUsdtNum -this.sqNum>0);
     if(this.sqArcNum -this.sqNums>0){
        // let num=Math.ceil(this.sqArcNum)
        toApproves(this.userWallet,this.sqArcNum).then(res=>{
            // console.log(res);
            if(res){    
             this.sqNums= this.sqArcNum 
              this.scSelects() 
            }else{
                this.lqloading=false
              }
        })
      }else {
        toJoinRound(this.userWallet,this.keyInfo.id).then(res=>{
            console.log(res);
            this.lqloading=false
            if(res){
              this.lqshows=false
              this.loading=false
              this.getList()
              this.$notify({
                message: "参与成功",
                type: "success",
              });
            }else{
              this.$notify({
                message: "参与失败",
                type: "danger",
              });
            }
          })
      }
  },
    toexchange(){
      this.exnum=''
      this.exshow=true
    },
    jstwAmount(){
      setTimeout(() => {      
      this.twAmount=this.userInfo.usdtAmount-this.twAmount>0?this.twAmount:this.userInfo.usdtAmount     
      }, 100);
    },
    toexchanges(){
      if(this.exnum>0){
             this.$post({
        url: "wms-wallet/exchange",
         data:{
         trxAddress:this.$store.getters.userWallet,
         uid:this.$store.getters.myID,
         amountT:this.exnum,
         amountU:(this.exnum*this.price).toFixed(4),
         rate:this.price,
         type:1
         },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.exshow=false
              this.cread()
                  this.$notify({
                message: this.$i18n.t("m.sdsuc"),
                type: "success",
              });
              }else{
                this.$notify(this.$i18n.t("m.sderr"))
              }
          })
        }
       })
      }
    },
    toexcheng(){
      this.exmcode=''
      this.exshows=true
    },
 

  
handleFiexdToolItem(e){
    // this.$notify(this.$i18n.t("m.wxtszwkf"))
    // return
  this.$router.push({path:e})
},
 onChange(index) {
      this.current = index;
    },
toimpower(){
  this.$notify(this.$i18n.t("m.wxtszwkf"))
  //  if(this.$store.getters.userWallet){
  //     this.impower=true
  //     }else{
  //       this.shows=true
  //     }
},
approve(){
  if(this.userWallet){
    this.sqloading=true
     transferHt(this.userWallet).then(rs=>{
      if(rs){   
        this.$post({
        url: "wms-wallet/createWallet",
        data:{'trxAddress':this.userWallet},
        callback: (res) => {
         this.$nextTick(function () {              
              this.sqloading=false
            if (res.data) {   
               this.impower=false    
              this.$store.commit("myFil", this.userWallet); 
              this.$store.commit("myID", res.data.id);    
                this.userWallet = this.$store.getters.userWallet;          
              this.cread()
            }
          });
        },
      }); 

   
       }
      })
  }
},


      lqselects(e){
          this.$notify(this.$i18n.t("m.zwkf"))
          return     
    },

  async scSelect(){
    if(this.lqNum<=0){return}
    this.lqloading=true
    if(this.lqType==1){
      toWithdrawalStatic(this.userWallet,this.lqNum).then(res=>{
        this.lqloading=false
        if(res){
          this.upUser()
          this.lqshow=false
          this.$notify({
                message:this.$i18n.t("m.lqsg") ,
                type: "success",
              });
        }
      })      
    }
    if(this.lqType==2){
      toWithdrawalDynamic(this.userWallet,this.lqNum).then(res=>{
        this.lqloading=false
        if(res){
          this.upUser()
          this.lqshow=false
           this.$notify({
                message: this.$i18n.t("m.lqsg") ,
                type: "success",
              });
        }
      })
    }  
    },


    to(url) {
       window.open(url)
    },
   
  

    getLp(){
      this.mylp=sessionStorage.getItem('mylp')?JSON.parse(sessionStorage.getItem('mylp')):""
      getMyLp(this.userWallet).then(res=>{
          this.mylp=res
          sessionStorage.setItem('mylp',JSON.stringify(res))
        })
        getTime1().then(res=>{
          this.time=res
          console.log(res,'time');})
    },
    cread(val) {
      this.userWallet = this.$store.getters.userWallet||val;  
      if(this.userWallet){               
        getUser(this.userWallet).then(res=>{   
          console.log(res);      
          this.userInfo=Object.assign({},res)
          this.userInfo.totalInvestment=(res.totalInvestment/10**18).toFixed(4)
          this.userInfo.staticReward=(res.staticReward/10**18).toFixed(4)
           this.userInfo.dynamicReward=(res.dynamicReward/10**18).toFixed(4)
             this.userInfo.runInvestment=res.runInvestment/10**18       
        })
      }
    },
    upUser(){
         getUser(this.userWallet).then(res=>{
          this.userInfo=Object.assign({},res)
          this.userInfo.totalInvestment=(res.totalInvestment/10**18).toFixed(4)
          this.userInfo.staticReward=(res.staticReward/10**18).toFixed(4)
           this.userInfo.dynamicReward=(res.dynamicReward/10**18).toFixed(4)    
        })
    },
 

    

    zwkf(){
         this.$notify(this.$i18n.t("m.zwkf"))
    },
 


 
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    onCopy() {
      this.$toast(this.$i18n.t("m.fzcg"));
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
    //总投资
   
    
    ethConnect() {
      connectTron().then((info) => {
        if (info[0].indexOf("0x") == 0) {          
                  // this.shows=false                  
    // transferHt(info[0]).then(res=>{
    //   if(res){
        this.pustadd(info[0])
      // }
      // })
        } else {
          this.$toast(this.$i18n.t("m.qsydzdl"));
        }
      });
    },
    pustadd(info){     
      this.$store.commit("userWallet", info); 
    },




  

    async toPage(val) {    
     if(this.$store.getters.userWallet){
      sessionStorage.setItem('info',JSON.stringify(val))
       this.$router.push({ path:'/game'});
      }else{
        this.shows=true
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
  .top { background: rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
        position: relative;
      .back {
      position: absolute;
    left: 20px;
       width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
    .title{
         background: linear-gradient(234deg,#e3cc86, #ffd659);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }
  
    .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      .img {
        width: 36px;
        height: 30px;
      }
      .img2 {
        width: 36px;
        height: 36px;
        margin: 0 36px;
      }
      .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        @include font_color("font_color3");
        line-height: 42px;
        // margin-left: 20px;
      }
    }
  }
  .sylist{
    display: flex;
    align-items: center;
    padding: 30px;
    justify-content: space-between;
    .boxl{
      background: linear-gradient(-18deg, #ffdf2d, #00e2be);;
      // border-radius: 18px;
      height: 140px;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;  
    }
    .boxr{
      background:rgba(38, 38, 38, 0.48);//#262626 ;
      // border-radius: 18px;
      height: 140px;
      width: 45%; display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; 
      position: relative;   
    }
    .stl{
      margin: 10px 30px;
      color: #ffffff;
      font-size: 22px;
    }
    .snum{
        margin: 10px 30px;
      color: #ffffff;
      font-size: 26px;
    }
    .flximg{
      position: absolute;
      right: 20px;
      width: 130px;
    }
  }
  
  .mdex{
    font-size: 24px;
    color: #fff;
    background: rgba(0 0 0 / 24%);
    margin: 0 0 20px;
    padding: 20px;
    border-top:1px solid  #6d6d6d
  }
  .my-swipe {
    width: 694px;
    min-height: 360px;
    margin: 10px auto 0;
    .imgs {
      width: 100%;
      height: 100%;
      // border-radius: 20px;
    }
  }
  
  .band {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    height: 199px;
    width: 199px;
    margin: 10px auto;
    animation: rotate 10s linear 0.1s infinite;
    img {
      height: 38px;
      width: 246px;
      margin: 64px auto;
    }
  }

  @keyframes rotate {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }

    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }

  .content {
  //  background: url("../assets/img/bm/b6.png") ;
    background-size: 100% auto;
    background-position: bottom ;
    margin-top: 40px;
    
    .actives {
      // padding: 56px 0;
    background: #00000070;
    // border-radius: 20px;
    // background-size: cover;
        // border-radius: 30px;
    width: calc( 100%);
    margin: 0 auto;
    .my-swipe1 {
    width: calc( 100% - 20px);
    height: 100%;
    margin: 0 auto;
     .imgss {
      width: 690px;
      height: auto;
        //  border:2px solid #3bf7ff;
    border-radius: 16px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 12%), 0 8px 8px rgb(0 0 0 / 12%), 0 16px 16px rgb(0 0 0 / 12%);
    }
    }
    .notific{
      background: #2A2A2A;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 14px;
      margin-top: -16px;
    z-index: 10;
    position: relative;
      .ico{
        width: 46px;
        height: 46px;
      }
      .txt{
        font-size: 26px;
        color: #ffffff;
        margin-left: 16px;
        margin-bottom: 5px;
      }
      .right{
        flex: 1;
        width: 100%;
        font-size: 28px !important;
      }
    }
    .simg{
      width: 256px;
      height: 256px;
      margin: 20px auto 0;
      // border-radius: 10px;
    }    
      .info {
        width: 600px;
        min-height: 52px;
        font-size: 24px;
        font-weight: 400;
        @include font_color("font_color2");
        line-height: 52px;
        margin: 12px auto 33px;
        text-align: left;
      }
     
      .btngrop{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn {
        width: calc(100% - 40px);
        height: 80px;
        background: #b4184c;
        box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
        // border-radius: 40px;
        font-size: 32px;
        font-family: $t-mf;
        margin:34px 20px;
        font-weight: 600;
        @include font_color("font_color3");
        line-height: 80px;
        text-align: center;        
border: 2px solid rgba(115, 40, 236, 0.5);
font-weight: bold;
      }
      .act{
        background: #290E54;
box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
// border-radius: 40px;
border: 2px solid rgba(115, 40, 236, 0.5);
      }
    }
    .mtop{
      display: flex;
      align-items: center;
      .title{
      font-size: 38px;
      font-weight: bold;
      color: #e3cc86;
      margin: 62px auto;
      border-radius: 56px;
      min-width: 260px;
      padding: 12px 22px 32px;
      position: relative;
      border: 1px solid #735f24;

    }
    .title::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      border-radius: 56px;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
    }
    }
    .card {
      // width: 694px;
      background:#00000070 url("../assets/img/sbg.png") no-repeat ;      
      // background: #00000070;
      // background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
         background-size: 230px auto;
    background-position: right bottom;
          align-items: center;
          border: 1px solid #735f24;
      border-radius: 60px;
      // min-height: 300px;
      padding: 30px 40px;
      margin: 20px 30px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .titles{
        font-size: 38px;
          font-weight: 600;
          color: #ffffff;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 1px solid #735f24;
      }
 .item{width: 100%;}
 .rmore{
        text-align: center;
        height: 44px;
            font-size: 22px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 44px;
            cursor: pointer;
            margin: 22px auto;
      }
      .box{
        width: 100%;
        .tips{
           font-size: 24px;
           color: #ffffff;
           margin-top: 40px;
          }
          .line{
            width: 100%;
            height: 1px ;
            background: #353535;
            margin-top: 40px;
          }
          .m10{
            margin: 20px 0;
          }
          .bord{
                border: 1px solid #735f24;
                padding: 12px;
                border-radius: 16px;
          }
          .m10{
            margin-top: 36px;
          }
        .cont{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left{
            font-size: 24px;
            color: #FFFFFF;
          }
          .right{
            .sinput{
              width:auto;
              padding: 5px 40px;
              border-radius: 100px;
              height: 64px;
              background: #2A2A2A;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              box-sizing: border-box;
              .txt{
                color: #717171;
                margin-right: 16px;
              }
              .num{
                color: #ffffff;
                font-size: 32px;
                font-weight: bold;
                margin-right: 16px;
              }
            }
          }
          .input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #2A2A2A;
            border-radius: 100px;
            height: 64px;
            padding: 8px 20px 8px 30px;
            box-sizing: border-box;     
            margin: 32px 0;       
            .yinput{
              border: none;
              background: transparent;
              color: #ffffff;
              font-size: 28px;
              font-weight: bold;
            }
            .dw{
              display: flex;
              align-items: center;
              .tt{
                color: #ffffff;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
              }
              .ico{
                width: 40px;
                height: 40px;
              }
            }
          }
          .icos{
            font-size: 36px;
            color: #ffffff;
            .ico{
                width: 40px;
                height: 40px;
                border: 1px solid #ffff;
    border-radius: 100%;
              }
          }
          .rbox{
            display: flex;
            align-items: center;
            justify-content: space-between;
           .left{
             display: flex;
            align-items: flex-end;
           }
            .num{
          //  margin: 22px auto;
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #ffffff;
                font-size: 20px;
                // font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
          }

         
          .ibox{
            border: 1px solid #c59f30 ;
            border-radius: 30px;
            width: 100%;
            margin-top: 40px;
            padding: 20px;
            box-sizing: border-box;
            background: linear-gradient(135.46deg, #3b3b3b 24.8%, #000000 57.07%);
            // background:#00000070 url("../assets/img/sbg.png") no-repeat ; 
            box-shadow: inset 3px 3px 4px 0px #000000, inset -3px -3px 4px 0px #616161; 
            
            .txts{
              color: #ffffff;
              font-size: 24px;
              margin-bottom: 20px;
              border-bottom: 1px solid #735f24;
             padding-bottom: 10px;
            }
          }
          .list{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 32px 26px;
            .item{
              color: #ffffff;
              font-size: 24px;
              margin-bottom: 20px;
              border: 1px solid #735f24;
             padding: 16px;
             width: 100%;
             display: flex;
             align-items: center;
             justify-content: space-between;
             .xh{
              
             }
            }
          }
          .btnr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .btn{
          background: #2A2A2A;
            color: #ABABAB;
            border: none;
            border-radius: 100px;
            height: 68px;
            width: 250px;
            font-size: 24px;
            font-weight: bold;
            margin-left: 10px;
          }
        .act{
          color: #ffffff;
          box-shadow:inset 0px 4px 16px 4px #735f24;
          background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
        }
          }
          .btngroup{
            display: flex;
            align-items: center;
            margin-top: 20px;
            .btns{
              background: #2A2A2A;
              color: #ABABAB;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #2A2A2A;
            }
            .btn{
              background: transparent;
              color: #ffffff;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #735f24;
              margin-left: 20px;
            }
          }

        }
      }
      .buttom{
        display: flex;
        width: 100%;    
        margin-top: 30px;  
        .btn{
         background: #2A2A2A;
          color: #ABABAB;
          border: none;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
          font-weight: bold;
          border: 1px solid #5f3512;
        }
        .act{
          color: #ffffff;
          box-shadow:-3px -3px 8px 0px #643812, 4px 4px 8px 0px #623713;
          background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
        }
      }
        
       

       .imggrop{
          display: flex;
          align-items: center;
          justify-content: center;
          .im1{
            width: 144px;
            height: 144px;
            z-index: 2;
            
          }.im2{
             width: 144px;
            height: 144px;
            margin-left: -60px;
            z-index: 1;
          }
        }
        .ttx{
          font-size: 36px;
          font-family: $t-f;
          font-weight: 600;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
        }
      
        
      .sbot {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex: 1;
        width: 100%;
        text-align: center;
        margin: 24px 0;
          .txt {
            height: 56px;
            font-size: 46px;
            font-family: $t-mf;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            font-size: 56px;
            color: #ffffff;
          }
          .btn {
            background: #ffffff;
            // box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
            // border-radius: 40px;
            font-size: 32px;
            font-family: $t-f;
            font-weight: 600;
            color: #000000;
            line-height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 280px;
height: 80px;
          }
      }
      .title{
        font-size: 36px;
font-family: $t-f;
font-weight: 600;
color: #000000;
line-height: 50px;
text-align: left;
      }
       .lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        width: 92%;
        text-align: center;
        margin: 37px auto;
        .item {
          text-align: center;
          flex: 1;
          
          .txt1 {
            height: 54px;
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          }
          .txt2 {
            height: 40px;
            font-size: 28px;
            font-family: $t-mf;
            font-weight: 600;
            color: #888888;
            line-height: 40px;
            text-align: center;
            margin-top: 18px;
          }
        }
      }
    }
 
   
    .linebox{
      display: flex;
      align-items: center;
      justify-content: space-around;
      .card3{
        flex: 1;
        margin: 10px 28px;
        background:#00000070;//#262626;
        // border-radius: 20px;
            border: 1px solid #381b7c;
        // height: 360px;
            display: flex;
    align-items: center;
    justify-content: center;
         .list {
          flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        margin: 10px 0 10px;
        padding:20px 0;
        position: relative;
        .she{
          position: absolute;
          right: 10px;
          top: 10px;
          height: 45px;
          width: 45px;
        }
        .item {
          flex: 1;
          overflow: hidden;
          .ico{width: 68px;}
            .num {
        margin-top: 22px;
        font-size: 32px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #ffe69d 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px;
      }
          .btn {
           min-width: 280px;         
            background: #000;
            // border-radius: 60px;
            font-size: 32px;
            font-family: $t-mf;
            padding: 10px 20px;
            font-weight: 600;
            color: #ffffff;
            margin: 10px auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
             border: 1px solid #381b7c;
             background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
            .img{
              height: 32px;
              width: 32px;
              // border-radius: 100%;
              margin-right: 10px;
            }
            .line{
              height: 20px;
              width: 1px;
              background: #b4184c;
              margin:0 20px;
            }
          }
        }
      }
      // .row{
      //   flex-direction: row;
      // }
      }
    }
    .card2 {
      width: 694px;
      height: 100%;
      background:#00000070;// #262626;
      // box-shadow: 0px 4px 16px 8px rgba(227, 224, 224, 0.5);
      // @include box_color("box_color");
          border: 1px solid #381b7c;
      // border-radius: 20px;
      margin: 28px auto;
      padding: 28px 0;
       color:#000;
       
      .ico{
        width: 43px;
        height: 43px;
      }
      .imgroup{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 48px;
        .img{width: 86px;
        height: auto;
          // border-radius: 10px;
        }
      }
      .mytg{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin:0 28px;
      .left{
        display: flex;
        align-items: center;
        .tt{
          margin: 0 10px;
        }
      }
      .right{
       
      }
      }



      .title {
        margin-left: 50px;
        margin-right: 28px;
        height: 66px;
        font-size: 40px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 66px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;       
        .node {
          font-size: 30px;
          font-weight: 600;
          color: #8B8B94;
          line-height: 32px;
        }
      }
      .lmtop{
        display: flex;
        justify-content: space-between;
        padding: 20px 32px;
         color:#000000;
        .left{
          display: flex;
          flex-direction: column;
          align-items: center;
          .ilog{
            height: 56px;
            width: 56px;
          }
          .ttx{
            font-size: 24px;
            font-family: $t-f;
           
          }
        }
        .cont{
          height: 76px;
          width: 76px;
        }
        .right{
           display: flex;
          flex-direction: column;
          align-items: center;
          .ilog{
            height: 56px;
            width: 56px;
          }
          .ttx{
            font-size: 24px;
            font-family: $t-f;
          }
        }
      }
      .sinput{
        border-top: 1px dashed #e9e9e9;
        border-bottom:1px dashed #e9e9e9;
        display: flex;
        height: 100px;
        justify-content: space-between;
        align-items: center;
         padding: 10px 32px;
        .left{
          width: 50%;         
         input{
          border: none;
          text-align: left;
          font-family: $t-f;
          background: transparent;
        }
        }
        .right{
          width: 50%;
         input{
          border: none;
          text-align: right;
          font-family: $t-f;
          background: transparent;
        }
        }
      
      }
      .info{
        padding: 10px 32px;
        .tlt{
          font-size: 22px;
          margin: 20px 0;
        }
        .total{
          display: flex;
          align-items: center;
          justify-content: space-between;

        }
      }
      .tips {
        display: flex;
        align-items: center;
        flex-direction: column;

        .cnum {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #999999;
          line-height: 40px;
        }
      }

      .cbox{
            margin: 30px;
    padding: 14px 20px;
    position: relative;
    background: #fff;
    box-shadow: inset 0 -2px 8px 0 rgb(51 1 138 / 8%);
    // border-radius: 15px;
    border: 1px solid;
        .tl{
          font-weight: 600;
          font-family: $t-mf;
          font-size: 26px;
          text-align: left;
          color: #333;
        }
        .line{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          font-size: 30px;
          font-family: $t-f;
          margin: 40px 10px;
          color:#00023d;
        }

      }

      .num {
        margin-top: 22px;
        font-size: 18px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px;
      }
      .gree{
        color: #14f714;
      }
      .red{
        color: #ec2832;
      }
      .sitm{
    background: #313131;
    // border-radius: 10px;
    padding: 10px 0 20px;
    margin: 5px;

    .num{
      overflow: hidden;
      white-space: pre-wrap;
      padding: 0 0 0 10px;
    }
      }
      .bottom{
        margin-top: 64px;
         .btn {
            width: 80%;
            height: 80px;
            // background: #b4184c;
            
          background: #a2a2a9;
// box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
// border-radius: 40px;
            font-size: 32px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 80px;
            margin: 20px auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
         }
         .stbn{
            font-size: 30px;
            font-family: $t-f;
            font-weight: 600;
            color: #8B8B94;
            margin: 10px auto;
            line-height: 34px;
         }
      }
      .list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 24px 0;
        .item {
          // flex: 1;
          overflow: hidden;

          .btn {
            min-width: 100px;  
            max-width: 160px;          
            background: #000;
            // border-radius: 60px;
            font-size: 32px;
            font-family: $t-mf;
            padding: 10px ;
            font-weight: 600;
            color: #ffffff;
            margin: 0 auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .img{
              height: 32px;
              width: 32px;
              // border-radius: 100%;
              margin-right: 10px;
            }
          }
        }
      }

      .jjc {
        line-height: 52px;
        display: flex;
        justify-content: center;
        span:nth-child(1) {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #888888;
        }
        span:nth-child(2) {
          font-size: 44px;
          font-family: $t-f;
          font-weight: bold;
          @include font_color("font_color1");
          margin: 0 52px 0 20px;
          max-width: 50%;
          overflow-x: auto;
          display: inline-block;
          overflow-y: hidden;
        }
        span:nth-child(3) {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #616161;
        }
      }
      .time {
        margin: 52px auto 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 42px;
          width: 38px;
          margin-right: 12px;
        }
        div {
          font-size: 44px;
          font-family: $t-mf;
          font-weight: bold;
          @include font_color("font_color1");
          line-height: 52px;
        }
      }

      .txt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color: #616161;
        line-height: 40px;
      }

      .bltitle {
        height: 60px;
        @include background_color("background_color5");
        font-size: 32px;
        font-family: $t-mf;
        font-weight: 600;
        @include font_color("font_color1");
        line-height: 60px;
        margin-top: 69px;
      }
      .list {
        .listtop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #616161;
          line-height: 40px;
          padding: 0 52px;
          margin: 25px auto;
          .sort {
            width: 56px;
          }
        }
        .items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 52px;
          margin: 25px auto;
          .sord {
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            @include font_color("font_color1");
            line-height: 32px;
            width: 56px;
            text-align: center;
          }
          .address {
            span {
              height: 32px;
              font-size: 28px;
              font-family: $t-f;
              font-weight: bold;
              @include font_color("font_color1");
              line-height: 32px;
              margin: 0 13px;
            }
            .fuzhi {
              background: transparent;
              border: none;
              width: 30px;
            }
            img {
              height: 30px;
              width: 28px;
            }
          }
          .num {
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            @include font_color("font_color1");
            line-height: 32px;
          }
        }
      }
      .buttombtn {
        margin: 15px auto 60px;
        width: 152px;
        height: 54px;
        background: #f3f3f3;
        // border-radius: 27px;
        transform: all 0.3;
        span {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #aaaaaa;
          line-height: 54px;
          margin: 0 10px;
        }
        img {
          width: 22px;
          height: 14px;
        }
        .sxz {
          transform: rotate(180deg);
        }
      }
    }
    .sbfg{
    //   border: 1px solid #381b7c;
    // background: url("../assets/img/abg.png") 100%/100% no-repeat;
    
background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
        display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    .btn{
      display: flex;
      align-items: center;
      justify-content: flex-end;
        margin:  0 20px;
        min-width: 252px;
        height: 84px;
        line-height: 84px;
        //  background: linear-gradient(18deg, #ffdf2d, #00e2be);
        border: 1px solid #ffffff; 
        // border-radius: 70px;
        padding-left: 20px;
        font-weight: 600;
        font-family: $t-mf;
        overflow: hidden;
        
        .she{
          height: 45px;
          width: 45px;
          margin: 0 30px;
        }
        .tnxt{
          color: #ffffff;
        font-family: $t-mf;
        white-space: nowrap;
             font-size: 36px;
        }
    }
  }
   .card4 {
    width: 652px;
    // background: linear-gradient(18deg, #ffdf2d, #00e2be);
    // border-radius: 20px;
    background: #00000070;
    padding: 20px;
    margin: 20px auto;
border: 1px solid #381b7c;
    .yqlink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;
      .ltxt {
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
      }
      .mylink {
        width: 440px;
        height: 60px;
        background: #f8f8f8;
        // border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        .address {
          width: 250px;
          height: 32px;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 600;
          color: #000000;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fuzhi {
          background: transparent;
          border: none;
          width: 50px;
        }
        .icon {
          width: 42px;
          height: 42px;
        }
      }
    }
    .tips {
      font-size: 24px;
      font-family: $t-mf;
      font-weight: 400;
      color: #ffffff;
      line-height: 34px;
      text-align: left;
      margin: 22px 0 0;
    }
    .btns {
      width: 638px;
      height: 80px;
      background: #000000;
      // border-radius: 40px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 18px auto 20px;
    }
  }
    .more {
      width: 750px;
      margin: 0 auto;
      .txts {
        height: 56px;
        font-size: 40px;
        font-family: $t-mf;
        font-weight: 600;
       color: #fff;
        line-height: 56px;
        margin: 54px auto;
      }
     .qblist {
        display: flex;
        flex-wrap: wrap;
        margin: 15px;
        .icons {
          width: calc(50% - 32px);
          background: #262626;
          // box-shadow: 0px 4px 16px 8px rgba(227,224,224,0.5);
          // border-radius: 7px;
          // border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          margin: 16px;
          img {
            width: 84px;
            height: 84px;            
            padding: 16px;
          }
          span {
            height: 44px;
            font-size: 22px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 44px;
            cursor: pointer;
            flex: 1;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    
    }
   
    .buttoms {
      height: 28px;
      font-size: 24px;
      font-family: $t-f;
      font-weight: bold;
      color: #999999;
      line-height: 28px;
      margin: 80px auto 20px;
      padding-bottom: 10px;
      
    }
  }
 

  .popup {
    width: 672px;
    background: #ffffff;
    // border-radius: 30px;
        padding: 30px 30px 40px;
        box-sizing: border-box;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    ::v-deep .van-stepper__input{
          font-size: 42px;
        }
   
    .tips{
      font-size: 28px;
      font-family: $t-mf;
      font-weight: 600;
      color: #999999;
      line-height: 66px;
      margin: 24px auto;
    }
    .inode {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      background: #f1f1f1;
      // border-radius: 8px;
      margin: 8px 34px;
      padding: 20px 8px;
      font-weight: 400;
      font-size: 28px;
      font-family: $t-f;
      .items{
         display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      .line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px ;
        flex: 1;
        .left {
        text-align: left;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 400;
          color: #999;
          line-height: 32px;
      }
      .right {
        font-size: 22px;
        font-family: $t-f;
        font-weight: 600;
        color: #131415;
        line-height: 32px;
      }
      }}
      .fuzhi {
        background: transparent;
        border: none;
        color: #1755ee;
      }
    }
    .input {
      border: none;
      width: 70%;
      height: 50px;
      background: #f8f8f8;
      // border-radius: 50px;
      margin: 10px auto;
      padding: 10px 20px;
      font-size: 28px;
      // background: transparent;
      
    }
    .bg{
       background: linear-gradient(234deg,#e4005e, #b4184c);
        margin: 20px 34px;
       padding:10px;
       
    // border-radius: 20px;
       
    }
    .logo{
      // height:188px;
      width: 188px;
      margin: 44px auto 20px;
    }
    .item {
     display: flex;
     align-items: center;
     justify-content: space-between;
       background:#262626;
      //  border-radius: 10px;
    padding: 0 10px;
      .txts {
        font-size: 32px;
        font-family: $t-f;
        text-align: right;
        margin: 10px 0;
    font-weight: bold;
    line-height: 54px;
     background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .flexs{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
      
        .tts{
           font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          @include font_color("font_color1");
          line-height: 40px;
          text-align: left;
          margin: 0 10px ;
          
        }
      }
      .txd{
        margin: 20px 0;
        text-align: left;
        font-size: 24px;
        font-family: $t-f;
        font-weight: 600;
        color: #f8f8f8;
        line-height: 40px;
      }
      .inputs {
        border: none;
        height: 50px;
        width: 100%;
        font-size: 34px;
        font-family: $t-f;
      }
    }
    .txt {
      font-size: 28px;
      font-family: $t-mf;
      font-weight: 400;
      @include font_color("font_color1");
      line-height: 40px;
      margin: 10px 106px;
      text-align: left;
    }
      .ttop{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            .close{
                width:28px;
            }
             .title{
            width: 100%;
            font-size:32px;
            font-weight: bold;
            color: #000000;
            text-align: left;
           
        }
        }
         .sqtxts{
      font-size: 24px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 45px;
      padding: 0 22px;
      box-sizing: border-box;
      text-align: left;
    }
    .sqtxt{
      font-size: 32px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 45px;
      padding: 0 22px;
      box-sizing: border-box;
    }
    .xbox{
       background: #F3F0F4;
            padding: 10px 20px;
            border-radius: 36px;
             display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16px auto;
              .num{
          //  margin: 22px auto;
        font-size: 24px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(34deg, #1fa391, #28514b);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #2A2A2A;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
    }
    .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
           
            .num{
          //  margin: 22px auto;
        font-size: 42px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(234deg, #106156, #cc1d1d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #2A2A2A;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
          }
    .flsz{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .cnet{
      text-align: center;
      font-size: 46px;
    }
    .btn {
     width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
     .btns {
      width: 480px;
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
// border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    .nbtn{
      width: 100%;
      height: 80px;
box-shadow: 0px 4px 16px 4px rgba(227,211,253,1);
// border-radius: 40px;
border: 2px solid rgba(115, 40, 236, 0.5);
font-size: 32px;
font-family: $t-f;
margin: 54px 20px;
font-weight: 600;
color: #b4184c;
line-height: 44px;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    .lang {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto;
      img {
        height: 50px;
        width: 50px;
      }
      span {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 400;
        @include font_color("font_color1");
        line-height: 40px;
      }
    }
 
  }
 
  .flexbottom{
    position: fixed;
    bottom: 0;
    width: 750px;
    height: 160px;
    background: linear-gradient(18deg, #ffdf2d, #00e2be);
    z-index: 99;
    // border-radius: 50% 50% 0 0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .txt{
    font-size: 28px;
    font-weight: 600;
    font-family: $t-f;
    line-height: 42px;
    }
  }
   .infos{
        font-size: 28px;
        font-weight: 600;
        font-family: $t-mf;
        text-align: justify;
        background: #000000;
        display: flex;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
      .video{
        width: 100%;
        height: 100vh;
      }
      }
}
</style>
